import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//nate

localStorage.setItem(
  'customMarkets',
  JSON.stringify([
    {
      address: '9sD9zrMApDm4a2Rraey4oxnuvoeXaCUbEwayAcnempSw',
      programId: '9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin',
      name: 'M6/USDT',
      baseLabel: 'M6',
    },
    {
      address: '93jQiktmPba5zhW2a5f1uzBx2dWwgUsuoADMjS19LJwp',
      programId: '9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin',
      name: 'M6/USDC',
      baseLabel: 'M6',
    },
    {
      address: '9tk1QTPpCyLwEHfdnJniGRSyepnjBpUHfQH6cXMZ6QrX',
      programId: '9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin',
      name: 'M6/BTC',
      baseLabel: 'M6',
    },
    {
      address: '8Gz6DoQNZV3Uzj6YgBfxAWG2T4t7yhHjzsNgT1NX6myr',
      programId: '9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin',
      name: 'M6/ETH',
      baseLabel: 'M6',
    },
    {
      address: 'HpGJZE3ggKfWzraKY5v21jFeY2o57qbAZYZbZWK2fawP',
      programId: '9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin',
      name: 'M6/SOL',
      baseLabel: 'M6',
    },
  ]),
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
